<template>
  <div class="mt-4" v-if="showDetail">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Update Task</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class="sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <div class=" sm:grid grid-cols-2">
            <div class="mb-4 col-span-2 ">
            <TextField
              :inputId="'jobtitlename'"
              :textMaxlength="100"
              :showcharLimit="true"
              :inputext="taskObject.taskTitle"
              :fieldError="errObj.titleErr !== ''"
              :placholderText="`Title`"
              :lableText="'Title'"
              :autoFocus="false"
              @keyPressAction="errObj.titleErr = ''"
              @inputChangeAction="(data) => taskObject.taskTitle = data"  />

              <p class="text-error heading-7">{{errObj.titleErr}}</p>
            </div>
            <div class=" col-span-2 mb-4">
              <div class="heading-5" v-if="!addTaskDesc">
                <span @click="addTaskDesc = true" class="text-primary font-bold cursor-pointer">Add Description</span>
              </div>
              <TextAreaInput v-if="addTaskDesc"
                :inputId="'discriptioncon'"
                :inputext="taskObject.taskDescription"
                :placholderText="`Write description here...`"
                :lableText="'Description'"
                :inputType="'text'"
                :autoFocus="false"
                :textMaxlength="1000"
                :showcharLimit="true"
                :cols="50"
                :rows="3"
                :setReadOnly="false"
                @inputChangeAction="(data) => taskObject.taskDescription = data"  />
            </div>
            <div class="mb-4">
                <Dropdown
                :inputext="searchForCustomer" 
                :inputId="'searchwp-Customer'"
                :lableText="'Client'"
                :placholderText="`Search Client`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :fieldError="errObj.custErr !== ''"
                :showPlus="true"
                :keyName="'companyName'"
                :listId="'customerlistid'"
                :items="customersList"
                @enterkeyPressAction="errObj.custErr =''"
                @inputChangeAction="callsearchCust"
                @addNew="addNewCustomer"
                @selectNewForVal="selectNewCustomer"
                />
                <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
            </div>
            <div v-if="taskObject.customerId > 0" class="mb-4 sm:mb-0 col-span-2">
              <div class="mb-4 sm:mb-0 col-span-2">
                <p class="text-text1 font-semibold">Select Location</p>
                  <customer-address-selection @setSelectedAddress="setSelectedAddress" :selectedAdrObj="this.taskObject.taskAddressObj" :lat="this.taskObject.taskAddressObj ? this.taskObject.taskAddressObj.latitude: 0" :long="this.taskObject.taskAddressObj ? this.taskObject.taskAddressObj.longitude: 0" :customerId="taskObject.customerId" />
              </div>
              <p v-if="errObj.addressErr !== ''" class="text-error heading-7">{{errObj.addressErr}}</p>
            </div>
            <div class="col-span-2">
              <SheduleVisit :taskDetail="taskDetail"/>
            </div>
          </div>
          <div class="flex justify-end ">
            <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Update'" @buttonAction="update()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="confPopup" class="popup_overlay relative px-4">
       <div style="width: 380px;" class="custom_dialog rounded-xl h-min p-4 text-center">
          <div class="mb-2">
            <p class="heading-3 text-text1">Select the task update option</p>
          </div>
          <div class="divider"></div>
          <div class="">
            <p class="heading-4 text-gray4 my-2 cursor-pointer hover:bg-gray-50" @click="updateTask(false)">Update only this task</p>
            <p class="heading-4 text-gray4 cursor-pointer hover:bg-gray-50" @click="updateTask(true)">Update this and all Future task</p>
          </div>
          <div  class="mb-5">
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="confPopup = false"/>
          </div>
      </div>
    </div>
    <AddCustomer v-if="addCustomer" />
   </div>
</template>
<script>
import moment from 'moment'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import SheduleVisit from './updTaskScheduleComp.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Dropdown from '@/View/components/dropdown.vue'
import CustomerAddressSelection from '../../../request/components/customerAddressSelection.vue';
export default {
  name: "customer",
  components: {
    Dropdown,
    AddCustomer,
    Button,
    TextAreaInput,
    TextField,
    SheduleVisit,
    CustomerAddressSelection,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      addCustomer: false,
      customersList: [],
      searchApitext: '',
      searchForCustomer: '',
      addTaskDesc: false,
      confPopup: false,
      showDetail: false,
      taskDetail: {},
      errObj: {
        custErr:'',
        titleErr:'',
        addressErr:''
      },
      scheduleDate: '',
      taskObject: {
        taskId: 0,
        customerId: 0,
        customerAddressId: 0,
        taskTitle: '',
        taskDescription: '',
        oldTaskDate: '',
        oldStartTimeTime: '',
        oldEndTimeTime: '',
        taskDate: '',
        startTime: '',
        endTime: '',
        reminderBeforeMinutes: '',
        assignToList: [],
        isTimeErr: false,
        taskAddressObj:null,
      },
      getCustList: () => {},
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    taskObject: {
      handler () {
        console.log('taskObject------------------->>>>>>>>----------------->>>>>', this.taskObject)
        if (this.taskObject.customerId > 0) {
          this.customersList.forEach(e => {
            if (e.customerId === this.taskObject.customerId) {
              this.taskObject.customerAddressId = 0
              this.errObj.custErr = ''
              this.searchForCustomer = e.companyName
            }
          });
        }
      },
      deep: true
    }
  },
  mounted() {
     this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
    this.getCustomerList()
    this.scheduleDate = this.$route.query.date
    this.getTaskDetail()
    this.taskObject.taskDate = moment(new Date()).format('YYYY-MM-DD')
    console.log("route", this.$route.query);
    this.$root.$on('setReminderVal', (response) => {
      console.log('response', response)
      this.taskObject.reminderBeforeMinutes = response.value
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.taskObject.assignToList = response
    })
    this.$root.$on('scheduleTimeEmit', (response) => {
      console.log('response 11', response)
      this.taskObject.startTime = response.compStartTime
      this.taskObject.endTime = response.compEndTime
      this.taskObject.taskDate = moment(new Date(response.compStartTime)).format('YYYY-MM-DD')
      this.taskObject.isTimeErr = response.Error
    })
  },
  beforeDestroy() {
    this.$root.$off("setReminderVal");
    this.$root.$off("setSelectedEmpHandler");
    this.$root.$off("scheduleTimeEmit");
  },
  methods: {
    setSelectedAddress(addresObj) {
        if (addresObj !== null) {
            this.taskObject.taskAddressObj = {
                addressLine1: addresObj.addressLine1 ? addresObj.addressLine1 : '',
                addressLine2: addresObj.addressLine2 ? addresObj.addressLine2 : '',
                city: addresObj.city ? addresObj.city : '',
                state: addresObj.state ? addresObj.state : '',
                zip: addresObj.zip ? addresObj.zip : '',
                country: addresObj.country ? addresObj.country : '',
                latitude: addresObj.latitude ? addresObj.latitude : 0,
                longitude: addresObj.longitude ? addresObj.longitude : 0,
                geofenceRangeDiameter: addresObj.geofenceRangeDiameter ? addresObj.geofenceRangeDiameter : 0,
                fullAddress: addresObj.fullAddress ? addresObj.fullAddress : '',
                houseNo: addresObj.houseNo ? addresObj.houseNo : '',
                floorNumber: addresObj.floorNumber ? addresObj.floorNumber : '',
                buildingName: addresObj.buildingName ? addresObj.buildingName : '',
                directionNote: addresObj.directionNote ? addresObj.directionNote : '',
                contactNumber: addresObj.contactNumber ? addresObj.contactNumber : '',
                contactName: addresObj.contactName ? addresObj.contactName : '',
                addressTitle: addresObj.addressTitle ? addresObj.addressTitle : '',
                customerId: addresObj.customerId ? addresObj.customerId : 0,
                isAddressAddToCustomer: addresObj.isAddressAddToCustomer ? addresObj.isAddressAddToCustomer : false,
            }
        } else {
            this.taskObject.taskAddressObj = null
        }
    },
    selectNewCustomer (data) {
        if (data !== null) {
          this.taskObject.customerAddressId = 0
          this.errObj.custErr = ''
          this.searchForCustomer = data.companyName
          this.taskObject.customerId = data.customerId
          this.taskObject.taskAddressObj = null
        } else {
            this.searchForCustomer = ''
            this.taskObject.customerId = 0
        }
    },
     addNewCustomer () {
        this.addCustomer = true
    },
    update () {
      console.log('this.taskObject---> SAVE---> ', this.taskObject)
      this.taskObject.assignToList = this.taskObject.assignToList === null ? [] : this.taskObject.assignToList
      if (this.taskObject.assignToList.length === 0) {
        this.taskObject.reminderBeforeMinutes = 0
      }
      let isValid = true
      if (this.taskObject.taskTitle.trim() === '') {
        this.errObj.titleErr = 'title is required'
        isValid = false
      }
      if (this.taskObject.isTimeErr) {
        isValid = false
      }
      if (this.taskObject.assignToList.length === 0) {
        this.$root.$emit('assigneeErr', true)
        isValid = false
      }
      if (isValid) {
        if (this.taskDetail.isRepeated) {
          this.confPopup = true
        } else {
          this.updateTask(false)
        }
      }
    },
    updateTask (value) {
      this.confPopup = false
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.UpdateTaskDetail(
        this.taskObject,
        value,
        (response) => {
            this.$router.push({name: 'TaskDetail', params: {taskId: response.Data}, query: {date: this.scheduleDate}})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    getTaskDetail () {
      MyJobApp.GetTaskDetail(
        parseInt(this.$route.params.taskId),
        moment(new Date(this.scheduleDate)).format('YYYY-MM-DD'),
        response => {
          this.taskDetail = response.Data !== null ? response.Data : null
          this.taskObject.taskTitle = response.Data.taskTitle
          this.taskObject.customerId = response.Data.customerId
          this.taskObject.taskId = response.Data.taskId
          this.taskObject.taskDescription = response.Data.taskDescription
          this.taskObject.taskDescription !== '' ? this.addTaskDesc = true : this.addTaskDesc = false 
          this.taskObject.oldTaskDate = moment(new Date(response.Data.taskDate)).format('YYYY-MM-DD')
          this.taskObject.oldStartTimeTime = response.Data.startTime
          this.taskObject.oldEndTimeTime = response.Data.endTime
          this.taskObject.taskDate = moment(new Date(response.Data.taskDate)).format('YYYY-MM-DD')
          this.taskObject.startTime = response.Data.startTime
          this.taskObject.endTime = response.Data.endTime
          this.taskObject.reminderBeforeMinutes = response.Data.reminderBeforeMinutes
          this.taskObject.assignToList = response.Data.assignToList
          this.showDetail = true
          if (this.taskDetail.latitude !== 0 && this.taskDetail.longitude !== 0) {
            this.taskObject.taskAddressObj = {
                addressLine1: this.taskDetail.addressLine1 ? this.taskDetail.addressLine1 : '',
                addressLine2: this.taskDetail.addressLine2 ? this.taskDetail.addressLine2 : '',
                city: this.taskDetail.city ? this.taskDetail.city : '',
                state: this.taskDetail.state ? this.taskDetail.state : '',
                zip: this.taskDetail.zip ? this.taskDetail.zip : '',
                country: this.taskDetail.country ? this.taskDetail.country : '',
                latitude: this.taskDetail.latitude ? this.taskDetail.latitude : 0,
                longitude: this.taskDetail.longitude ? this.taskDetail.longitude : 0,
                geofenceRangeDiameter: this.taskDetail.geofenceRangeDiameter ? this.taskDetail.geofenceRangeDiameter : 0,
                fullAddress: this.taskDetail.fullAddress ? this.taskDetail.fullAddress : '',
                houseNo: this.taskDetail.houseNo ? this.taskDetail.houseNo : '',
                floorNumber: this.taskDetail.floorNumber ? this.taskDetail.floorNumber : '',
                buildingName: this.taskDetail.buildingName ? this.taskDetail.buildingName : '',
                directionNote: this.taskDetail.directionNote ? this.taskDetail.directionNote : '',
                contactNumber: this.taskDetail.contactNumber ? this.taskDetail.contactNumber : '',
                contactName: this.taskDetail.contactName ? this.taskDetail.contactName : '',
                addressTitle: this.taskDetail.addressTitle ? this.taskDetail.addressTitle : '',
                customerId: this.taskDetail.customerId ? this.taskDetail.customerId : 0,
                isAddressAddToCustomer: this.taskDetail.isAddressAddToCustomer ? this.taskDetail.isAddressAddToCustomer : false,
            }
          } else {
             this.taskObject.taskAddressObj = null
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.showDetail = true
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
     callsearchCust (text) {
      this.searchApitext = text
     this.getCustList()
    },
    getCustomerList () {
      this.customersList = []
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        '',
        response => {
            let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
           tempList.forEach(e => {
            let lead = e.isLead ? '(lead)' : '(customer)'
            e.companyName = e.companyName + ' ' + lead
            this.customersList.push(e)
           });
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
};
</script>
<style scoped>

</style>