<template>
  <div class="text-text1 w-full min-w-full">
    <p class="mb-2">Schedule</p>
    <div class="date_picker_box ">
      <div class=" md:grid gap-4 grid-cols-2">
        <div class="flex">
          <div class="mb-4 w-full">
            <TextField :isPointer="true" :placholderText="`Start Date`" :setReadOnly="true" :autoFocus="false" :inputext="sendObj.schedStartDate" :showDate="true" :lableText="'Start Date'" :inputId="'Invoicedkid'" @onClickInputAction="startDatePikcer = true" />
            <div v-if="startDatePikcer" class="popup_overlay">
              <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                <DateTimePickerCustom
                    v-model="sendObj.schedStartDate"
                    v-if="startDatePikcer"
                    :showDate="true"
                    :title="'Start Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    :projectSection="true"
                    @closeDateTimepickerDialog="startDatePikcer = false">
                </DateTimePickerCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" md:grid gap-4 grid-cols-2">
        <div v-if="!isAnyTime" class="mb-4">
          <TimePicker :title="'Start Time'" v-model="startTime"/>
        </div>
        <div v-if="!isAnyTime" class="mb-4">
          <TimePicker :title="'End Time'" v-model="endTime"/>
          <p v-if="timeErr" class="text-error mb-2">End time should be greater than Start time</p>
        </div>
            <!-- <div class="w-full mb-4">
              <div v-if="isAnyTime" class="card bg-gray1 items-center rounded-xl p-4  w-full flex gap-2 text-text1  mb-4">
                <div class="flex justify-between gap-2 items-center">
                  <p>Time:</p>
                  <div class="font-semibold heading-4">
                    Anytime
                  </div>
                </div>
              </div>
               <div class="flex justify-end">
                <p v-if="!isAnyTime" @click="isAnyTime = true" class="text-primary cursor-pointer">Clear Time</p>
                <p v-else @click="isAnyTime = !isAnyTime" class="text-primary cursor-pointer">Set Time</p>
              </div>
            </div> -->
      </div>
    </div>
    <div class=" grid grid-cols-2 gap-4">
      <div class="mb-4">
        <MultiSelect 
        :inputext="employeeSearchText" 
        :inputId="'empslectinpuid'"
        :lableText="'Team'"
        :placholderText="`Select Team`"
        :autoFocus="false"
        :showArrow="true"
        :setReadOnly="false"
        :searchCallApi="false"
        :showPlus="false"
        :keyName="'fullName'"
        :idKey="'userDetailId'"
        :sectionId="'employeelistsecid'"
        :listId="'employeelistidb'"
        :items="employeeList"
        :selectedValues="selectedEmployee"
        @selectNewForVal="employeeSelectedClick"
        :fieldError="assigneeErr !== ''"
        />
        <p class="text-error mb-2" v-if="assigneeErr !== ''">{{assigneeErr}}</p>
      </div>
      <div class="mb-4 sm:mb-0" v-if="selectedEmployee.length > 0">
        <Dropdown 
            :inputext="selectReminderVal" 
            :inputId="'ReminderValue'"
            :lableText="'Team Reminder'"
            :placholderText="`Select Reminder`"
            :autoFocus="false"
            :showArrow="true"
            :setReadOnly="true"
            :searchCallApi="false"
            :showPlus="false"
            :keyName="'title'"
            :listId="'remId'"
            :items="reminderList"
            @selectNewForVal="updRemValue"
            />
      </div>
    </div>
  </div>
</template>
<script>
// import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import TimePicker from '@/View/job/components/timePicker.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
export default {
  name: 'ScheduleVisit',
  components: {
    MultiSelect,
    Dropdown,
    // CheckBoxInput,
    TimePicker,
    TextField,
    DateTimePickerCustom,
  },
  data () {
    return {
      monthObj: [
        {id: 0, value: 'Day of Month', isSel: false},
        {id: 1, value: 'Day of Week', isSel: false},
      ],
      assigneeErr: '',
      openCustomSel: false,
      startDatePikcer: false,
      endDatePicker: false,
      selectedMonth: [],
      tempWeeokMonthArr: [],
      sendObj: {
        isRepeat: false,
        schedStartDate: '',
        schedEndDate: '',
      },
        isUnSchJob: false,
        typeForVi: '',
        employeeSearchText: '',
        selectedEmployee: [],
        employeeList: [],
        frequencyList: [
          {freqId: 0, title: 'Daily'},
          {freqId: 2, title: 'Weekly'},
          {freqId: 3, title: 'Monthly'},
        ],
        selectedWeek: [],
        dateValue: 1,
        radioModel: 0,
        reminderList: [
          {remId: 0, title: 'No reminder set', value: -1},
          {remId: 1, title: 'At start of task', value: 0},
          {remId: 2, title: '5 min prior', value: 5},
          {remId: 3, title: '10 min prior', value: 10},
          {remId: 4, title: '15 min prior', value: 15},
          {remId: 5, title: '30 min prior', value: 30},
          {remId: 6, title: '1 hour prior', value: 60},
          {remId: 7, title: '2 hours prior', value: 120},
          {remId: 8, title: '5 hours prior', value: 300},
          {remId: 9, title: '12 hours prior', value: 720},
          {remId: 10, title: '24 hours prior', value: 1440}
        ],
        numberOfVisit: 0,
        repeatViType: '',
        selectFreqVal: 'Daily',
        selectReminderVal: 'No reminder set',
        selectedRepeat: 'Do not Repeat',
        timeErr: false,
        isAnyTime: false,
        startTime: "11:30",
        endTime: "11:30",
        firstVisit: "",
        lastVisit: "",
        isMultiVisit: true,
        selectedVisits: [],
        finalVisitList: [],
    }
  },
  props: ['taskDetail'],
   created() {
  },
  beforeMount () {
    this.sendObj.schedStartDate = moment(new Date(this.taskDetail.startTime)).format('YYYY-MM-DD')
    this.sendObj.schedEndDate = moment(new Date(this.taskDetail.endTime)).format('YYYY-MM-DD')
    this.setTimes(this.taskDetail.startTime, this.taskDetail.endTime)
    this.reminderList.forEach(element => {
      if (element.value === this.taskDetail.reminderBeforeMinutes) {
        this.selectReminderVal = element.title
      }
    });
    if (this.taskDetail.assignToList.length > 0) {
      this.selectedEmployee = []
      this.taskDetail.assignToList.map(el => {
        this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
      })  
    }
  },
  mounted () {
    // console.log('this.selectedEmployee', this.selectedEmployee)
    // this.setHourTime()
    this.getEmployeeList()
    this.$root.$on('assigneeErr', (response) => {
      if (response) {
        this.assigneeErr = 'Assignee is required'
      } else {
        this.assigneeErr = ''
      }
    })
    console.log('his.tempWeeokMonthArr', this.tempWeeokMonthArr)
  },
  watch: {
    isAnyTime: {
      handler () {
        if (this.isAnyTime) {
            this.startTime = '00:00'
            this.endTime = '23:59'
        } else {
          let hours = new Date().getHours()
          let min = new Date().getMinutes()
          hours = min > 45 &&  min < 60 ? hours + 1 : hours
          let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
          
          this.startTime = hours + ':' + minutes
          this.endTime = hours + 2 + ':' + minutes
        }
      }
    },
    sendObj: {
      handler () {
        console.log('sendObj', this.sendObj)
        this.setFullTIme()
      }, 
      deep: true
    },
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setFullTIme()
      },
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
          let hour = parseInt(x[0])
          let min = parseInt(x[1])
         let y = this.endTime.split(':')
          let hr2 = parseInt(y[0])
          let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setFullTIme()
      },
    }
  },
  methods: {
    setTimes (start, end) {
      console.log('startstartstartstartstartstartstartstart', start)
      console.log('startstartstartstartstartstartstartstart', end)
      let hours = new Date(start).getHours()
      let min = new Date(start).getMinutes() 
      this.startTime = hours + ':' + min

      let hours2 = new Date(end).getHours()
      let min2 = new Date(end).getMinutes()
      this.endTime = hours2 + ':' + min2
    },
    setFullTIme () {
      let temp1 = moment(this.sendObj.schedStartDate).format('YYYY-MM-DD')
      let strDate = temp1 + ' ' + this.startTime
      let endDate = temp1 + ' ' + this.endTime
      let Obj = {
        compStartTime: new Date(strDate).toUTCString(),
        compEndTime: new Date(endDate).toUTCString(),
        Error: this.timeErr,
      }
      setTimeout(() => {
        this.$root.$emit('scheduleTimeEmit', Obj)
      }, 200);
    },
    employeeSelectedClick (emplist) {
      this.assigneeErr = ''
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
          fullName: data.fullName
        }
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(obj)
      })
      setTimeout(() => {
         this.$root.$emit('setSelectedEmpHandler', tempEmpList)
      }, 200);
    },
    updRemValue (data) {
      console.log('data', data)
      this.selectReminderVal = data !== null ? data.title : 'No reminder set'
      this.$root.$emit('setReminderVal', data)
      // this.$root.$emit('setSheduleDataEmpHandler',{repeatedType: this.repeatViType, repeatedDayOrDate: data.val})
    },
    setHourTime () {
      let hours = new Date().getHours()
      let min = new Date().getMinutes()
      hours = min > 45 &&  min < 60 ? hours + 1 : hours
      let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
      
      this.startTime = hours + ':' + minutes
      this.endTime = hours + 1 + ':' + minutes
      console.log('123 call, 1', this.startTime)
      console.log('123 call, 1', this.endTime)
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
            })
          }
          this.employeeList = tempList
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
}
</script>
<style scoped>
</style>